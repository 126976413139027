@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.receive-orders-table {
  font-size: $body-font-size;
  margin-top: 1.25rem;

  table {
    margin-bottom: 0;
  }

  thead th {
    background-color: $secondary-colour-light-blue;
    white-space: nowrap;
    padding-block: 1.25rem;

    &:first-child,
    &:last-child {
      width: 2rem;
    }
  }

  table tbody {
    border: 1px solid $border-colour;

    a {
      @extend .primary-link;
    }

    tr {
      border-color: inherit;
      vertical-align: middle;

      td,
      th {
        padding-block: 1rem;
      }

      th {
        padding-inline: 1rem;
      }
    }

    > tr:not(.expandable) {
      border-color: transparent;

      th {
        cursor: pointer;
      }
    }

    > tr.expandable {
      > td {
        padding: 0;
      }
    }

    .po-number {
      white-space: nowrap;
    }
  }

  .issues {
    padding: 0;
    color: $warning;
    margin-right: 0.25rem;
  }

  .inner-wrapper {
    padding: 0 1.5rem 0.75rem 2rem;
    max-height: 35rem;
    overflow-y: auto;
  }

  table.nested {
    position: relative;
    margin-bottom: 0;

    thead th {
      position: sticky;
      top: 0;
      background-color: $primary-colour-lightest;
      font-weight: normal;
      padding-block: 1rem;
      padding-inline: 0.75rem;

      &.outstanding-col {
        width: 5%;
      }
    }

    tbody {
      border: none;

      tr {
        cursor: default;
        border-bottom: 1px solid $border-colour;
      }

      td {
        padding: 0.75rem;
      }
    }

    .spn {
      width: 10%;
    }

    .product {
      width: 30%;

      p {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .pack-size {
      width: 10%;
    }

    .outstanding {
      background-color: $base-colour-gold;
    }

    .due-date {
      width: 10%;
    }
  }

  :global {
    .pagination {
      background-color: $secondary-colour-light-blue;

      .page-item {
        background-color: $secondary-colour-light-blue;
      }

      .page-item:not(.active) {
        .page-link {
          background-color: inherit;
        }
      }
    }
  }
}
