@import '../../../common/assets/scss/variables';

.trigger {
  height: fit-content;
  color: $warning;
  padding: 0;
}

.text {
  max-width: 50vw;
  white-space: pre-line;
  overflow-wrap: anywhere;

  p {
    margin-bottom: 0.5rem;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
